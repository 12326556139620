import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import "../scss/header.min.css";
import LOGO_DARK from '../assets/svg/logo.svg';
import LOGO_WHITE from '../assets/svg/logowhite.svg';

import $ from 'jquery';
import Marquee3k from 'marquee3000';
window.jQuery = $;


// require('../../node_modules/marquee3000/marquee3k');

export default class Header extends Component {
  componentDidMount() {
    Marquee3k.init();
  }

  render() {
    return (
      <div className="header_layout">

        <div className="marquee3k" data-speed="0.5"
          data-reverse="false"
          data-pausable="true">
          <img src={LOGO_WHITE} alt="Logo" />
       </div>
      </div>
    )
  }
}
