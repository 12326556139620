import React from "react";
import "../scss/body_1.min.css";




import main_bg_left_one from "../assets/svg/main_bg_left_one.svg";
import main_bg_right_one from "../assets/svg/main_bg_right_one.svg";
import main_bg_top_right from "../assets/svg/main_bg_top_right.svg";

import main_bg_pentagon1 from "../assets/svg/main_bg_pentagon1.svg";
import main_bg_pentagon2 from "../assets/svg/main_bg_pentagon2.svg";
import main_bg_pentagon3 from "../assets/svg/main_bg_pentagon3.svg";

import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaGithub,
  FaBehance,
} from "react-icons/fa";

export default function Body_1() {
  return (

    <section className="Body_1">
      <div className="main_bg_top_right"><img src={main_bg_top_right} alt="" /></div>
      <div className="main_bg_left_one"><img src={main_bg_left_one} alt="" /></div>
      <div className="main_bg_right_one"><img src={main_bg_right_one} alt="" /></div>
      <div className="container header_wrapper">

        <div className="main_bg_pentagon1"><img src={main_bg_pentagon1} alt="" /></div>
        <div className="main_bg_pentagon2"><img src={main_bg_pentagon2} alt="" /></div>
        <div className="main_bg_pentagon3"><img src={main_bg_pentagon3} alt="" /></div>
        <div className="box_layout_container">
          <h1>Design is a Solution<br></br>to a Problem</h1>

          <div className="button_box_container">
            <a href="https://diptanuchakraborty.in" target="_blank">Services</a>
          </div>
          {/* button_box_container */}

          <div className="inner_text_box">
            <h5>Get in touch</h5>
            <div className="social_icons">
              <a id="fb_icon" href="https://www.facebook.com/diptanuchakravarty"><FaFacebook></FaFacebook></a>
              <a id="insta_icon" href="https://www.instagram.com/diptnc/"><FaInstagram></FaInstagram></a>
              <a id="li_icon" href="https://www.linkedin.com/in/diptanuchakraborty/"><FaLinkedin></FaLinkedin></a>
              <a id="git_icon" href="https://github.com/diptnc"><FaGithub></FaGithub></a>
              <a id="be_icon" href="https://www.behance.net/diptnc"><FaBehance></FaBehance></a>
            </div>
            {/* social icons */}

            <div className="contact_info">
              <h5>website : <a href="https://diptanuchakraborty.in">www.diptanuchakraborty.in</a></h5>
              <h5>Email : <a href="mailto:chakrabortyd943@gmail.com">chakrabortyd943@gmail.com</a></h5>
              <h5>whatsapp/telegram : <a href="javascript:">+919774966680</a></h5>
            </div>
          </div>
          {/* inner tex box */}

        </div>
        {/* box_layout_container */}

      </div>
      {/* container */}
    </section>
    // Body_1
  );
}
