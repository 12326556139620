import React from 'react'
import '../scss/footer.min.css'
export default function Footer() {
    return (
<>

<footer>
    <div className="footer_element">
       <h5>© {new Date().getUTCFullYear()} All Rights Reserved by <a href="https://diptanuchakraborty.in">diptanuchakraborty.in</a></h5>
       <div className="footer_submenu">
           <a href="https://designstudio.diptanuchakraborty.in/sitemap.xml"><h5>Sitemap</h5> </a><h5>|</h5><a href="https://diptanuchakraborty.in"><h5>Portfolio</h5></a> 
       </div>
    </div>
</footer>


</>

    );
}
