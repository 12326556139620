import Header from "./components/Header";
import Footer from "./components/Footer";
import Body_1 from "./components/Body_1";
function App() {
  return (
    <>
      <Header></Header>
      <Body_1></Body_1>
      <Footer></Footer>
    </>
  );
}

export default App;
